import React from 'react'
import type { FC } from 'react'
import { gql } from '@vtex/gatsby-plugin-graphql'
import { graphql } from 'gatsby'
import {
  HybridWrapper,
  useQuery,
  useSearchParamsFromUrl,
} from '@vtex/gatsby-theme-store'
import Layout from 'src/components/Layout'
import { useQueryVariablesFromSearchParams } from 'src/sdk/search/useQueryVaraiblesFromSearchParams'
import { Helmet } from 'react-helmet'
import { SuspenseSSR } from '@vtex/store-ui'
import EventCategory from 'src/views/collection/components/Event'
import CollectionView from 'src/views/collection'
import AboveTheFoldPreview from 'src/views/collection/components/AboveTheFoldPreview'
import type { PageProps as GatsbyPageProps } from 'gatsby'
import loadable from '@loadable/component'
import { EventProductClickSetListParamProvider } from 'src/contexts/EventProductClickSetListParamContext'

import { BrowserCollectionPageQuery } from './__generated__/BrowserCollectionPageQuery.graphql'
import type {
  ServerCollectionPageQueryQuery,
  ServerCollectionPageQueryQueryVariables,
} from './__generated__/ServerCollectionPageQuery.graphql'
import type {
  BrowserCollectionPageQueryQuery,
  BrowserCollectionPageQueryQueryVariables,
} from './__generated__/BrowserCollectionPageQuery.graphql'

const CustomEvent = loadable(() => import('src/components/common/CustomEvent'))
const EventView = loadable(() => import('src/components/common/EventView'))
const EventDetailsPage = loadable(
  () => import('src/components/common/EventDetailsPage')
)

const pageInfo = { size: Number(process.env.GATSBY_STORE_PLP_ITEMS_PER_PAGE) }

const SearchPage: FC<PageProps> = (props) => {
  const { location, data: staticData, pageContext } = props

  const searchParams = useSearchParamsFromUrl(location)
  const variables = useQueryVariablesFromSearchParams(searchParams, pageInfo)

  const { data: dynamicData } = useQuery<
    BrowserCollectionPageQueryQuery,
    BrowserCollectionPageQueryQueryVariables
  >({
    ...BrowserCollectionPageQuery,
    variables,
    suspense: true,
  })

  if (dynamicData == null) {
    throw new Error('Something went wrong while fetching the data')
  }

  const data = { ...dynamicData, ...staticData }

  return (
    <EventProductClickSetListParamProvider>
      <SuspenseSSR fallback={null}>
        <EventCategory data={data} />
        <CustomEvent type="PLPs" data={data} />
        <EventView type="CATEGORY" />
        <EventDetailsPage
          data={data}
          type="CATEGORY"
          cmsSEO={data.storeCollection}
        />
      </SuspenseSSR>
      <CollectionView
        data={data}
        searchParams={searchParams}
        pageInfo={pageInfo}
        params={{ slug: pageContext.slug }}
      />
    </EventProductClickSetListParamProvider>
  )
}

/**
 * This query is run on the browser and contains
 * the current search state of the user
 */
export const clientSideQuery = gql`
  query BrowserCollectionPageQuery(
    $to: Int!
    $from: Int!
    $selectedFacets: [VTEX_SelectedFacetInput!]!
    $sort: String!
  ) {
    vtex {
      productSearch(
        to: $to
        from: $from
        orderBy: $sort
        selectedFacets: $selectedFacets
        hideUnavailableItems: false
        simulationBehavior: skip
      ) {
        products {
          ...ProductSummary_product
        }
        recordsFiltered
      }
      facets(
        selectedFacets: $selectedFacets
        operator: or
        behavior: "Static"
        removeHiddenFacets: true
      ) {
        breadcrumb {
          href
          name
        }
        facets {
          name
          type
          values {
            key
            name
            value
            selected
            quantity
            range {
              from
              to
            }
          }
        }
      }
    }
  }
`

type PageProps = GatsbyPageProps<
  ServerCollectionPageQueryQuery,
  ServerCollectionPageQueryQueryVariables & { slug: string }
>

const Page: FC<PageProps> = (props) => {
  const canonicalMetatag = `https://www.avon.com.br/${props.location.pathname}`
    .replace('.br//', '.br/')
    .replace('.br///', '.br/')
    .replace(/\/$/, '')

  return (
    <>
      <Helmet>
        <link rel="canonical" href={canonicalMetatag} />
      </Helmet>
      <Layout>
        <HybridWrapper fallback={<AboveTheFoldPreview />}>
          <SearchPage {...props} />
        </HybridWrapper>
      </Layout>
    </>
  )
}

/**
 * This query is run during SSG
 * */
export const serverSideQuery = graphql`
  query ServerCollectionPageQuery($id: String!) {
    cmsSeo {
      seo {
        siteMetadata {
          titleTemplate
          title
          description
        }
      }
    }
    storeCollection(id: { eq: $id }) {
      seo {
        title
        description
      }
      meta {
        selectedFacets {
          key
          value
        }
      }
    }
  }
`

export default Page
